.ant-back-top {
  right: 60px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #01af4d;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 24px;
}
.ant-back-top {
  bottom: 10px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  font-size: 24px;

  height: 35px;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 30px;
  width: 35px;
  z-index: 10;
}
html,
body,
#root {
  height: 100%;
}
* {
  transition: all 0.6s;
}

html {
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
  color: #888;
  margin: 0;
}

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
* {
  transition: all 0.6s;
}

html {
  height: 100%;
}

body {
  font-family: "SolaimanLipi";
  color: rgb(29, 29, 29);
  margin: 0;
}

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
